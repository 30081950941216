import React from "react";
import clientConfig from "../../client-config";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import { getFluidGatsbyImage } from "gatsby-source-sanity";
import { Link } from "gatsby";

const ProjectPreview = ({ title, mainImage, slug }) => {
  const fluidProps = getFluidGatsbyImage(
    mainImage.asset,
    {
      maxWidth: 750,
      maxHeight: Math.floor((16 / 9) * 750),
    },
    clientConfig.sanity
  );
  return (
    <div className="relative w-full sm:w-1/2 md:w-1/4 px-2 pb-4 group">
      <Link
        to={`/project/${slug.current}`}
        className="overflow-hidden bg-black rounded-sm shadow"
      >
        {mainImage && mainImage.asset && (
          <Img
            style={{ height: "100%" }}
            className=" w-full object-cover group-hover:opacity-75 transition duration-300"
            fluid={fluidProps}
            alt={mainImage.alt}
          />
        )}
        <div className="absolute bottom-0 right-0 left-0 text-center align-middle w-full px-2 py-4 md:hidden group-hover:block transition duration-300">
          <h2 className="py-8 px-4 bg-gray-900 bg-opacity-75 text-white font-semibold h-3/12">
            {title}
          </h2>
        </div>
      </Link>
    </div>
  );
};

ProjectPreview.propTypes = {
  title: PropTypes.string.isRequired,
  mainImage: PropTypes.object,
  slug: PropTypes.object,
};

export default ProjectPreview;
