import React from 'react';
import PropTypes from 'prop-types';

const Heading = ({heading, id}) => (
  <h2 id={id} className="uppercase font-bold text-4xl text-blue-branding pt-4 pb-2">
    {heading}
  </h2>
)

Heading.propTypes = {
  heading: PropTypes.string.isRequired,
  id: PropTypes.string
}

export default Heading;