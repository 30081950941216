import React from 'react'
import PropTypes from 'prop-types'
import ProjectPreview from './project-preview';
import Heading from './heading';

const ProjectPreviewList = ({heading, projects}) => (
  <section className="px-8 max-w-screen-xl p-4 mx-auto md:px-8">
    <Heading heading={heading} />
    <div className="flex flex-wrap mt-4 -mx-2">
      {projects.filter(p => !p.disabled).map(project => <ProjectPreview key={project.id} {...project} />)}
    </div>
  </section>
)

ProjectPreviewList.propTypes = {
  heading: PropTypes.string,
  projects: PropTypes.array
};

export default ProjectPreviewList;